// 레슨 화면에 학생들이 보이는 순서를 결정한다
import { isOn } from "../utils/stat";
import { getClasses, ordering, tagColor_Time, 레슨대기자표시, 레슨마침표시, 레슨중표시, 미등원배지표시, 상태표시 } from "../components/common/chulsFunc";
import { fieldSorter } from "../components/common/func1";
const { 결석, 등원, 레슨마침, 레슨시작, 하원 } = require("../gma/Stats");

const MakeButtons = ({ students, day, btnSize, badgeSize, order, showTime, showHak }) => {
  if (!students) return null;
  students = tagColor_Time(students, day);

  let v레슨중 = students
    .filter(m => isOn(m, 레슨시작) && !isOn(m, 레슨마침) && !isOn(m, 결석))
    .sort(fieldSorter(["onTime"])); //등원시각
  v레슨중.map(m => {
    m.myButton = getClasses(m, btnSize, badgeSize);
    m.myBadge = 레슨중표시(m, btnSize, badgeSize);
    return null;
  });
  let v레슨마침 = students
    .filter(m => isOn(m, 레슨마침) && !isOn(m, 하원) && !isOn(m, 결석))
    .sort(fieldSorter(["offTime"]));
  v레슨마침.map(m => {
    m.myButton = getClasses(m, btnSize, badgeSize);
    m.myBadge = 레슨마침표시(m, btnSize, badgeSize);
    return null;
  });

  let v등원 = students
    .filter(m => isOn(m, 등원) && !isOn(m, 레슨시작) && !isOn(m, 결석))
    .sort(fieldSorter(["offTime"]));
  v등원.map(m => {
    m.myButton = getClasses(m, btnSize, badgeSize);
    m.myBadge = 레슨대기자표시(m, btnSize, badgeSize);
    return null;
  });

  students = ordering(order, students);
  let v미출석 = students.filter(
    m => !isOn(m, 등원) && !isOn(m, 하원) && !isOn(m, 결석)
  );
  if (order === "time") {
    v미출석 = students
      .filter(m => !isOn(m, 등원) && !isOn(m, 하원) && !isOn(m, 결석))
      .sort(fieldSorter(["time"]));
  }
  v미출석.map(m => {
    m.myButton = getClasses(m, btnSize, badgeSize);
    m.myBadge = 미등원배지표시(
      m,
      showTime,
      showHak,
      btnSize,
      badgeSize
    );
    return null;
  });
  let v하원 = students
    .filter(m => isOn(m, 하원) && !isOn(m, 결석))
    .sort(fieldSorter(["name"]));
  v하원.map(m => {
    m.myButton = getClasses(m, btnSize, badgeSize);
    m.myBadge = 상태표시(m, btnSize, badgeSize);
    return null;
  });
  let v결석 = students
    .filter(m => isOn(m, 결석))
    .sort(fieldSorter(["name"]));
  v결석.map(m => {
    m.myButton = getClasses(m, btnSize, badgeSize);
    m.myBadge = 상태표시(m, btnSize, badgeSize);
    return null;
  });
  return {
    레슨중: [...v레슨중],
    등원: [...v등원],
    레슨마침: [...v레슨마침],
    미출석: [...v미출석],
    하원: [...v하원],
    결석: [...v결석
    ]
  }
}
export default MakeButtons